export default {
  NONE: {
    display: 'None',
    value: ''
  },
  ENTRYPOINT: {
    display: 'EntryPoint',
    value: 'ENTRYPOINT'
  },
  STRIPE: {
    display: 'Stripe (Legacy)',
    value: 'STRIPE'
  }
}
